.navbar-container
{
    padding: 0;
}

img.logo
{

   visibility: hidden;
   width: 0px; 
}

.logo-nomobile
{
    background-color: white;
    text-align: center;
}

.logo-nomobile img.logo
{
    visibility: visible;
    width: 240px;
    padding-top: 30px;
}



.custom-navbar
{
    min-height: 4rem;
    padding: 0;
    /* box-shadow: 1px 1px 4px #0000004b; */
    width: 100%;
    z-index: 4;
    background-color: white !important;
    
}

button.navbar-toggler
{
    border: none;
}

span.navbar-toggler-icon
{
    color: blue !important;
}



.nav-link
{
    color: black !important;
    transition: .2s;
    font-size: 1.24rem;
    font-family: 'Outfit';
    font-weight: 700;
    margin-left: 6px;
    margin-right: 6px;
}

.nav-link:hover
{
    color: #91c8ff !important;
}
.order-btn
{
    color: #1f8fff !important;
    transition: .2s;
}

.order-btn:hover
{
    text-shadow: 2px 5px 8px rgba(0, 0, 0, 0.082) !important;
}



.sticky
{
    position: fixed;
    top: 0;
}

.sticky + .empty
{
    height: 4rem;
}

@media screen and (max-width: 991px)
{
    .custom-navbar
    {
        min-height: 3rem;
    }

    .empty
    {
    height: 3rem;
    }

    .custom-navbar a
    {
        margin-left: 8px;
    }


    .fixed-mobile
    {
        position: fixed;
        top: 0;
    }

    img.logo
    {
    
       visibility: visible;
       margin-left: -60px;
       width: 120px;
       transition: 1s;
       
        
    }

    

    img.logo-scrolled
    {
        width: 48px;
        margin-left: -50px;
        
    }
    
    .logo-nomobile
    {
        background-color: white;
        text-align: center;
        height: 0px;
    }
    
    .logo-nomobile img.logo
    {
        visibility: hidden;
        margin-left: 0;
        width: 0px;
        transition: 0s;

    }

    
}