@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;700&family=Outfit:wght@400;700&display=swap);
html
{
  scroll-behavior: smooth;
  
}

.text-centered
{
  text-align: center;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: 0;
  
}

p 
{
  font-family: 'Playfair Display';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-btn-style, .main-btn-style:active
{
  background-color: #212121 !important;
  border-color: #212121 !important;
  color: ivory;
  font-family: Outfit !important;
  border-radius: 0px;
}

.main-btn-style:enabled:hover
{
  background-color: #545454 !important;
  border-color: #545454 !important;
  color: ivory;
}

body
{
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/static/media/shutterstock_1828593056_v2.8e0abaa4.jpg);
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.navbar-container
{
    padding: 0;
}

img.logo
{

   visibility: hidden;
   width: 0px; 
}

.logo-nomobile
{
    background-color: white;
    text-align: center;
}

.logo-nomobile img.logo
{
    visibility: visible;
    width: 240px;
    padding-top: 30px;
}



.custom-navbar
{
    min-height: 4rem;
    padding: 0;
    /* box-shadow: 1px 1px 4px #0000004b; */
    width: 100%;
    z-index: 4;
    background-color: white !important;
    
}

button.navbar-toggler
{
    border: none;
}

span.navbar-toggler-icon
{
    color: blue !important;
}



.nav-link
{
    color: black !important;
    transition: .2s;
    font-size: 1.24rem;
    font-family: 'Outfit';
    font-weight: 700;
    margin-left: 6px;
    margin-right: 6px;
}

.nav-link:hover
{
    color: #91c8ff !important;
}
.order-btn
{
    color: #1f8fff !important;
    transition: .2s;
}

.order-btn:hover
{
    text-shadow: 2px 5px 8px rgba(0, 0, 0, 0.082) !important;
}



.sticky
{
    position: fixed;
    top: 0;
}

.sticky + .empty
{
    height: 4rem;
}

@media screen and (max-width: 991px)
{
    .custom-navbar
    {
        min-height: 3rem;
    }

    .empty
    {
    height: 3rem;
    }

    .custom-navbar a
    {
        margin-left: 8px;
    }


    .fixed-mobile
    {
        position: fixed;
        top: 0;
    }

    img.logo
    {
    
       visibility: visible;
       margin-left: -60px;
       width: 120px;
       transition: 1s;
       
        
    }

    

    img.logo-scrolled
    {
        width: 48px;
        margin-left: -50px;
        
    }
    
    .logo-nomobile
    {
        background-color: white;
        text-align: center;
        height: 0px;
    }
    
    .logo-nomobile img.logo
    {
        visibility: hidden;
        margin-left: 0;
        width: 0px;
        transition: 0s;

    }

    
}
