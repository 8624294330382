@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;700&family=Outfit:wght@400;700&display=swap');




html
{
  scroll-behavior: smooth;
  
}

.text-centered
{
  text-align: center;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: 0;
  
}

p 
{
  font-family: 'Playfair Display';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-btn-style, .main-btn-style:active
{
  background-color: #212121 !important;
  border-color: #212121 !important;
  color: ivory;
  font-family: Outfit !important;
  border-radius: 0px;
}

.main-btn-style:enabled:hover
{
  background-color: #545454 !important;
  border-color: #545454 !important;
  color: ivory;
}
